<template>
  <div class="fracpack-container">
    <div class="fracpack-container__inner">
      <PackVideo 
        v-if="showVideo" 
        :src="openedPackVideo" 
        @onVideoEnd="handleOnVideoEnd" 
      />

      <template v-if="showContent">
        <div class="flex items-center flex-1">
          <ImageResize :src="pack.img" alt="frac_pack" type="frac_pack" />
        </div>

        <div class="headline-large mt-s48">
          {{ $t('frac_pack.congratulations') }}
        </div>

        <div class="subheadline-large mt-s16 mb-s32">
          {{ $t('frac_pack.open_your_frac_pack') }}
        </div>

        <div class="w-full">
          <ButtonV2
            @onClick="handleOpenFracPack"
            class="mt-s64"
            :inactive="isLoading"
            :label="$tc('frac_pack.open_pack_button')"
            testId="btn-packButton"
            :size="isSmallMobileDevice ? 'medium' : 'large'"
            wide
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import FracpackState from '@/enums/FracpackState';

import { ButtonV2, ImageResize } from '@/components/misc';
import { PackVideo } from '@/modules/pack';

export default {
  name: 'FracPack',
  components: {
    ButtonV2,
    PackVideo,
    ImageResize,
  },

  data() {
    return {
      isLoading: false,
      showContent: true,
      showVideo: false,
      packOpenProps: {
        noPadding: true,
        isClosable: false,
      },
    };
  },

  mounted() {
    if (this.pack.variation === 'fracpack') {
      this.setCurrentFracpack(this.pack);
    }
  },

  props: {
    pack: Object,
  },

  computed: {
    ...mapGetters('fracpack', [
      'fracpackTrackingData',
    ]),

    ...mapState('pack', [
      'packFinalize',
      'openedPackVideo',
      'bypassPackVideo',
    ]),

    isFracpack(){
      return this.pack.variation === 'fracpack';
    },
    isPack(){
      return this.pack.variation === 'pack';
    },
    isRollFracpack(){
      return this.isFracpack && this.pack.state === FracpackState.UNOPENED;
    },
    isReviewFracpack(){
      return this.isFracpack && this.pack.state === FracpackState.OPENED;
    },
  },

  methods: {
    ...mapActions('fracpack', [
      'rollFracpack',
      'setCardsInside',
      'setCurrentFracpack',
      'showCardsModal',
    ]),
    ...mapActions('pack', [
      'openPack',
    ]),
    ...mapActions('ui', [
      'setModalProps'
    ]),

    handleOnVideoEnd() {
      if (this.isPack) {
        return this.showModal('PackOpen', {
          fracPack: this.packFinalize,
          noPadding: true,
          isClosable: false,
        });
      } else {
        return this.showCardsModal();
      }
    },

    async handleOpenFracPack() {
      try {
        this.isLoading = true;

        if (this.isRollFracpack) {
          this.$store.dispatch('events/track', {
            event: 'FRAC_PACK_OPENED',
            variables: {
              ...this.fracpackTrackingData,
            },
          });
          return this.rollFracpack(this.pack.id);
        }

        if (this.isReviewFracpack) {
          return this.setCardsInside(this.pack.cards_inside);
        }

        await this.openPack(this.pack);
      } catch (err) {
        await this.showError(err);
        this.hideModal();
      } finally {
        this.isLoading = false;
      }
    },
    showOpenPack() {
      return this.showModal('PackOpen', {
        ...this.packOpenProps, 
        fracPack: this.packFinalize
      });
    },
  },

  watch: {
    openedPackVideo(src) {
      if(src) {
        this.setModalProps({ ...this.packOpenProps });
        this.showContent = false;
        this.showVideo = true;
      }
    },
    packFinalize(pack) {
      if(pack && this.bypassPackVideo) {
        return this.showOpenPack();
      }
    },
  }
};
</script>

<style scoped>
.fracpack-container {
  @apply 
    flex
    flex-col
    justify-center
    items-center
    overflow-hidden
    p-s24
    pt-0
  ;
  min-width: 375px;
}
.fracpack-container__inner {
  @apply 
    flex
    flex-col
    flex-1
    justify-center
    items-center
    w-full
    overflow-hidden
  ;
}
</style>
